import React, { useState } from 'react';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';

const CreateUser = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [docId, setDocId] = useState("");

  // Function to reset form fields
  const resetForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setDocId("");
  };

  const createUser = async () => {
    try {
      await setDoc(doc(db, 'users', docId), {
        username,
        email,
        password,
        imageUrl: "", // Initialized with an empty string
        mailbox: [],
        sentMessages: []
      });

      alert('User created successfully');
      resetForm();
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  return (
    <div className='newUser'>
      <form className='newUserForm'>
        <input type="text" placeholder="Profile ID" value={docId} onChange={(e) => setDocId(e.target.value)} />
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="button" onClick={createUser}>Create User</button>
      </form>
    </div>
  );
};

export default CreateUser;
