import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase'; // Assuming you've already imported this
import { doc, updateDoc } from 'firebase/firestore';

const ImageUploader = ({ userId }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    const storage = getStorage();
    const metadata = { contentType: 'image/jpeg' };

    // Create a storage reference using the user's ID
    const storageRef = ref(storage, 'images/' + userId + '/' + file.name);

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.log("Upload error: ", error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);

          // Update Firestore document for the user with the new imageUrl
          const userDocRef = doc(db, 'users', userId);
          await updateDoc(userDocRef, {
            imageUrl: downloadURL,
          });
        } catch (error) {
          console.error("Failed to update Firestore: ", error);
        }
      }
    );
  };

  return (
    <div className='uploadImage'>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default ImageUploader;
