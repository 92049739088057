import React from 'react';

const Footer = () => {
  return (
    <footer>
        <div className="copyright-div">
          <p>Copyright &copy; LxRose</p>
        </div>
    </footer>
  );
};

export default Footer;
